/**
 * 定义一个变量，该变量对应createRouter函数中的参数options对象中的routes，其类型为数组
 * 数组中每个对象里面包含path路径，必须；name名称非必须(给当前引入的这个组件起一个名字)，component:引入组件
 * webpackChunkName： 打包之后生成的js文件名称
 */
const routes = [
    {
        name: '/offline',
        path: '/offline',
        component: () => import('@/pages/error/Offline.vue'),
        meta: { title: '下线通知' }
    },
    {
        name: '/error',
        path: '/error',
        component: () => import('@/pages/error/Index.vue'),
        meta: { title: '无权访问', toPath: '' }
    },

    {
        name: '/login',
        path: '/login',
        component: () => import('@/pages/login/Login.vue'),
        meta: { title: '登录', toPath: '' }
    },
    {
        name: '/home',
        path: '/home',
        // path: '/',
        component: () => import('@/pages/home/Index.vue'),
        meta: { title: '主页' }
    },
    {
        path: '/work',
        name: '/work',
        component: () => import('@/pages/Index.vue'),
        meta: { title: '工作区' },
        children: [
            //权限不足路由
            {
                name: '/noAuthority',
                path: '/noAuthority',
                component: () => import('@/pages/error/NoAuthority.vue'),
                meta: { title: '重复登录' }
            },
            //公共商品查询路由
            {
                name: '/goods',
                path: '/goods',
                // redirect: '/goods/square',
                component: () => import('@/pages/goods/Index.vue'),
                meta: { title: '模具专区' },
                children: [
                    {
                        name: '/goods/cart',
                        path: '/goods/cart',
                        component: () => import('@/pages/goods/Cart.vue'),
                        meta: { title: '购物车' }
                    },
                    {
                        name: '/goods/square',
                        path: '/goods/square',
                        component: () => import('@/pages/goods/Square.vue'),
                        meta: { title: '方柱系列' }
                    },
                    {
                        name: '/goods/corner',
                        path: '/goods/corner',
                        component: () => import('@/pages/goods/Corner.vue'),
                        meta: { title: '包角柱系列' }
                    },
                    {
                        name: '/goods/wall',
                        path: '/goods/wall',
                        component: () => import('@/pages/goods/Wall.vue'),
                        meta: { title: '贴墙柱系列' }
                    },
                    {
                        name: '/goods/columnAll',
                        path: '/goods/columnAll',
                        component: () => import('@/pages/goods/ColumnAll.vue'),
                        meta: { title: '柱子选配' }
                    },
                    {
                        name: '/goods/cylinder',
                        path: '/goods/cylinder',
                        component: () => import('@/pages/goods/Cylinder.vue'),
                        meta: { title: '圆柱系列' }
                    },
                    {
                        name: '/goods/rail',
                        path: '/goods/rail',
                        component: () => import('@/pages/goods/Rail.vue'),
                        meta: { title: '栏杆系列' }
                    },
                    {
                        name: '/goods/pier',
                        path: '/goods/pier',
                        component: () => import('@/pages/goods/Pier.vue'),
                        meta: { title: '柱墩系列' }
                    },
                    {
                        name: '/goods/line',
                        path: '/goods/line',
                        component: () => import('@/pages/goods/Line.vue'),
                        meta: { title: '线条系列' }
                    },
                    {
                        name: '/goods/corbel',
                        path: '/goods/corbel',
                        component: () => import('@/pages/goods/Corbel.vue'),
                        meta: { title: '梁托系列' }
                    },
                    {
                        name: '/goods/wind',
                        path: '/goods/wind',
                        component: () => import('@/pages/goods/Wind.vue'),
                        meta: { title: '罗马窗套' }
                    },
                    {
                        name: '/goods/archWind',
                        path: '/goods/archWind',
                        component: () => import('@/pages/goods/ArchWind.vue'),
                        meta: { title: '罗马窗拱' }
                    },
                    {
                        name: '/goods/light',
                        path: '/goods/light',
                        component: () => import('@/pages/goods/Light.vue'),
                        meta: { title: '光面窗套' }
                    },
                    {
                        name: '/goods/pendant',
                        path: '/goods/pendant',
                        component: () => import('@/pages/goods/Pendant.vue'),
                        meta: { title: '挂件摆件' }
                    },
                    {
                        name: '/goods/laundry',
                        path: '/goods/laundry',
                        component: () => import('@/pages/goods/Laundry.vue'),
                        meta: { title: '洗衣池' }
                    },
                    {
                        name: '/goods/flowerpot',
                        path: '/goods/flowerpot',
                        component: () => import('@/pages/goods/Flowerpot.vue'),
                        meta: { title: '花盆系列' }
                    },
                    {
                        name: '/goods/paint',
                        path: '/goods/paint',
                        component: () => import('@/pages/goods/Paint.vue'),
                        meta: { title: '油漆系列' }
                    },
                    {
                        name: '/goods/cornerPier',
                        path: '/goods/cornerPier',
                        component: () => import('@/pages/goods/CornerPier.vue'),
                        meta: { title: '包角柱墩' }
                    },
                    {
                        name: '/goods/wallPier',
                        path: '/goods/wallPier',
                        component: () => import('@/pages/goods/WallPier.vue'),
                        meta: { title: '贴墙柱墩' }
                    },
                    {
                        name: '/goods/eps',
                        path: '/goods/eps',
                        component: () => import('@/pages/goods/Eps.vue'),
                        meta: { title: 'EPS成品' }
                    },
                    // {
                    //     name: '/goods/search-goods',
                    //     path: '/goods/search-goods',
                    //     component: () => import('@/components/goods/SearchGoods.vue'),
                    //     meta: { title: '模具查询' }
                    // },
                    {
                        name: '/goods/three',
                        path: '/goods/three',
                        component: () => import('@/pages/goods/Three.vue'),
                        meta: { title: '3D模型' },
                    },
                    {
                        name: '/goods/windThree',
                        path: '/goods/windThree',
                        component: () => import('@/pages/goods/WindThree.vue'),
                        meta: { title: '3D模型' },
                    },
                    {
                        name: '/goods/railThree',
                        path: '/goods/railThree',
                        component: () => import('@/pages/goods/RailThree.vue'),
                        meta: { title: '3D模型' },
                    },
                    {
                        name: '/goods/railAllThree',
                        path: '/goods/railAllThree',
                        component: () => import('@/pages/goods/RailAllThree.vue'),
                        meta: { title: '3D模型' },
                    },
                    {
                        name: '/goods/generalThree',
                        path: '/goods/generalThree',
                        component: () => import('@/pages/goods/GeneralThree.vue'),
                        meta: { title: '3D模型' },
                    },
                ]

            },
            //文件查询
            {
                name: '/file',
                path: '/file',
                // redirect: '/file/imageSpace',
                component: () => import('@/pages/file/Index.vue'),
                meta: { title: '森谷网盘' },
                children: [
                    {
                        name: '/file/imageSpace',
                        path: '/file/imageSpace',
                        component: () => import('@/pages/file/ImageSpace.vue'),
                        meta: { title: '图片空间' },
                    },
                    {
                        name: '/file/imageManage',
                        path: '/file/imageManage',
                        component: () => import('@/pages/file/ImageManage.vue'),
                        meta: { title: '图片管理' },
                    },
         
                    {
                        name: '/file/videoManage',
                        path: '/file/videoManage',
                        component: () => import('@/pages/file/VideoManage.vue'),
                        meta: { title: '视频管理' },
                    },
                    {
                        name: '/file/video',
                        path: '/file/video',
                        component: () => import('@/pages/file/VideoSpace.vue'),
                        meta: { title: '视频空间' },
                    },
                    {
                        name: '/file/threeModelManage',
                        path: '/file/threeModelManage',
                        component: () => import('@/pages/file/ThreeModelManage.vue'),
                        meta: { title: '3D模型' },
                    },
                    {
                        name: '/file/fileDownload',
                        path: '/file/fileDownload',
                        component: () => import('@/pages/file/FileDownload.vue'),
                        meta: { title: '文件导出' },
                    },
                    {
                        name: '/file/recycle',
                        path: '/file/recycle',
                        component: () => import('@/pages/file/Recycle.vue'),
                        meta: { title: '回收站' },
                    },
                ]
            },

            //订单管理路由
            {
                name: '/storage',
                path: '/storage',
                // redirect: '/storage/orders',
                component: () => import('@/pages/storage/Index.vue'),
                meta: { title: '仓储管理' },
                children: [
                    {
                        name: '/storage/orders',
                        path: '/storage/orders',
                        component: () => import('@/pages/storage/Orders.vue'),
                        meta: { title: '订单查询' }
                    },
                    {
                        name: '/storage/ordersManage',
                        path: '/storage/ordersManage',
                        component: () => import('@/pages/storage/OrdersManage.vue'),
                        meta: { title: '订单管理' }
                    },
                    {
                        name: '/storage/returnOrder',
                        path: '/storage/returnOrder',
                        component: () => import('@/pages/storage/ReturnOrder.vue'),
                        meta: { title: '售后查询' }
                    },
                    {
                        name: '/storage/returnOrderManage',
                        path: '/storage/returnOrderManage',
                        component: () => import('@/pages/storage/ReturnOrderManage.vue'),
                        meta: { title: '售后管理' }
                    },
                    {
                        name: '/storage/orderPost',
                        path: '/storage/orderPost',
                        component: () => import('@/pages/storage/OrderPost.vue'),
                        meta: { title: '快递单号' }
                    },
                    {
                        name: '/storage/storePost',
                        path: '/storage/storePost',
                        component: () => import('@/pages/storage/StorePost.vue'),
                        meta: { title: '仓库快递' }
                    },
                    {
                        name: '/storage/postFee',
                        path: '/storage/postFee',
                        component: () => import('@/pages/storage/PostFee.vue'),
                        meta: { title: '快递费用' }
                    },
                    {
                        name: '/storage/fillOrdersPostage',
                        path: '/storage/fillOrdersPostage',
                        component: () => import('@/pages/storage/FillOrdersPostage.vue'),
                        meta: { title: '录入邮费' }
                    },
                    {
                        name: '/storage/thirdPartyOrder',
                        path: '/storage/thirdPartyOrder',
                        component: () => import('@/pages/storage/ThirdPartyOrder.vue'),
                        meta: { title: '站外订单' }
                    },
                    {
                        name: '/storage/thirdPartyOrderManage',
                        path: '/storage/thirdPartyOrderManage',
                        component: () => import('@/pages/storage/ThirdPartyOrderManage.vue'),
                        meta: { title: '站外单管理' }
                    },

                    {
                        name: '/storage/invoice',
                        path: '/storage/invoice',
                        component: () => import('@/pages/storage/Invoice.vue'),
                        meta: { title: '发票查询' }
                    },
                    {
                        name: '/storage/workOrder',
                        path: '/storage/workOrder',
                        component: () => import('@/pages/storage/WorkOrder.vue'),
                        meta: { title: '工单查询' }
                    },
                    {
                        name: '/storage/workOrderManage',
                        path: '/storage/workOrderManage',
                        component: () => import('@/pages/storage/WorkOrderManage.vue'),
                        meta: { title: '工单管理' }
                    },
                    {
                        name: '/storage/reship',
                        path: '/storage/reship',
                        component: () => import('@/pages/storage/Reship.vue'),
                        meta: { title: '补发查询' }
                    },
                    {
                        name: '/storage/reshipManage',
                        path: '/storage/reshipManage',
                        component: () => import('@/pages/storage/ReshipManage.vue'),
                        meta: { title: '补发管理' }
                    },
                    {
                        name: '/storage/orderCost',
                        path: '/storage/orderCost',
                        component: () => import('@/pages/storage/OrderCost.vue'),
                        meta: { title: '转账查询' }
                    },
                ]
            },

            //任务管理路由
            {
                name: '/task',
                path: '/task',
                // redirect: '/task/task',
                component: () => import('@/pages/task/Index.vue'),
                meta: { title: '任务管理' },
                children: [
                    {
                        name: '/task/task',
                        path: '/task/task',
                        component: () => import('@/pages/task/Task.vue'),
                        meta: { title: '任务查询' }
                    },
                    {
                        name: '/task/taskManage',
                        path: '/task/taskManage',
                        component: () => import('@/pages/task/TaskManage.vue'),
                        meta: { title: '任务管理' }
                    },

                    {
                        name: '/task/taskSet',
                        path: '/task/taskSet',
                        component: () => import('@/pages/task/TaskSet.vue'),
                        meta: { title: '系统设置' }
                    },
                ]
            },
            //画图路由
            {
                name: '/draw',
                path: '/draw',
                // redirect: '/draw',
                component: () => import('@/pages/draw/Index.vue'),
                meta: { title: '绘图' },
            },
            //那些年路由
            {
                name: '/imageAlbum',
                path: '/imageAlbum',
                // redirect: '/draw',
                component: () => import('@/pages/file/ImageAlbum.vue'),
                meta: { title: '森谷3D相册' },
            },

            //系统管理员路由
            {
                name: '/sysManage',
                path: '/sysManage',
                // redirect: '/manage/goods',
                component: () => import('@/pages/manage/Index.vue'),
                meta: { title: '系统管理' },
                children: [
                    {
                        name: '/manage/goods',
                        path: '/manage/goods',
                        component: () => import('@/pages/manage/Goods.vue'),
                        meta: { title: '产品管理' },
                    },
                    {
                        name: '/manage/deputy',
                        path: '/manage/deputy',
                        component: () => import('@/pages/manage/Deputy.vue'),
                        meta: { title: '产品辅助' },
                    },
                    {
                        name: '/manage/commodity',
                        path: '/manage/commodity',
                        component: () => import('@/pages/commodity/Index.vue'),
                        meta: { title: '平台商品' },
                    },
                    {
                        name: '/manage/activity',
                        path: '/manage/activity',
                        component: () => import('@/pages/manage/Activity.vue'),
                        meta: { title: '活动管理' },
                    },

                    {
                        name: '/manage/postFirm',
                        path: '/manage/postFirm',
                        component: () => import('@/pages/storage/PostFirm.vue'),
                        meta: { title: '快递公司' },
                    },
                    {
                        name: '/user/account',
                        path: '/user/account',
                        component: () => import('@/pages/user/Account.vue'),
                        meta: { title: '账号管理' },
                    },
                    {
                        name: '/user/menu',
                        path: '/user/menu',
                        component: () => import('@/pages/user/Menu.vue'),
                        meta: { title: '权限管理' },
                    },
                    {
                        name: '/user/menuSet',
                        path: '/user/menuSet',
                        component: () => import('@/pages/user/MenuSet.vue'),
                        meta: { title: '权限管理' },
                    },
                    {
                        name: '/user/role',
                        path: '/user/role',
                        component: () => import('@/pages/user/Role.vue'),
                        meta: { title: '角色管理' },
                    },
                    {
                        name: '/user/loginRecords',
                        path: '/user/loginRecords',
                        component: () => import('@/pages/user/LoginRecords.vue'),
                        meta: { title: '登录记录' },
                    },
                    {
                        name: '/manage/shop',
                        path: '/manage/shop',
                        component: () => import('@/pages/manage/Shop.vue'),
                        meta: { title: '店铺资料' },
                    },
                    {
                        name: '/manage/sub',
                        path: '/manage/sub',
                        component: () => import('@/pages/manage/Sub.vue'),
                        meta: { title: '订阅管理' },
                    },
                    {
                        name: '/manage/assessCondi',
                        path: '/manage/assessCondi',
                        component: () => import('@/pages/manage/AssessCondi.vue'),
                        meta: { title: '考核设置' },
                    },
                    {
                        name: '/manage/empAccountProcess',
                        path: '/manage/empAccountProcess',
                        component: () => import('@/pages/manage/EmpAccountProcess.vue'),
                        meta: { title: '职员账户' },
                    },
                    {
                        name: '/manage/wagesManage',
                        path: '/manage/wagesManage',
                        component: () => import('@/pages/manage/WagesManage.vue'),
                        meta: { title: '职员月薪' },
                    },
                    {
                        name: '/manage/empSalaryManage',
                        path: '/manage/empSalaryManage',
                        component: () => import('@/pages/manage/EmpSalaryManage.vue'),
                        meta: { title: '月薪设置' },
                    },
                    {
                        name: '/hr/rankManage',
                        path: '/hr/rankManage',
                        component: () => import('@/pages/hr/RankManage.vue'),
                        meta: { title: '班次设置' }
                    },
                    {
                        name: '/manage/salaryAdditionalManage',
                        path: '/manage/salaryAdditionalManage',
                        component: () => import('@/pages/manage/SalaryAdditionalManage.vue'),
                        meta: { title: '考核奖罚' }
                    },
                    {
                        name: '/manage/workflowManage',
                        path: '/manage/workflowManage',
                        component: () => import('@/pages/manage/WorkflowManage.vue'),
                        meta: { title: '流程审批' }
                    },
                    {
                        name: '/manage/threeView',
                        path: '/manage/threeView',
                        component: () => import('@/pages/manage/ThreeView.vue'),
                        meta: { title: '3D视图' }
                    },
                    {
                        name: '/manage/workLog',
                        path: '/manage/workLog',
                        component: () => import('@/pages/manage/WorkLog.vue'),
                        meta: { title: '日志查询' }
                    },
                    {
                        name: '/manage/businessDataManage',
                        path: '/manage/businessDataManage',
                        component: () => import('@/pages/manage/BusinessDataManage.vue'),
                        meta: { title: '经营审核' }
                    },
                    {
                        name: '/manage/trade',
                        path: '/manage/trade',
                        component: () => import('@/pages/manage/Trade.vue'),
                        meta: { title: '目标配置' }
                    },

                ]
            },
            //产品编辑路由
            {
                name: '/editGoods',
                path: '/editGoods',
                component: () => import('@/pages/manage/EditGoods.vue'),
                meta: { title: '编辑产品' },
            },
            //人事行政路由
            {
                name: '/hols',
                path: '/hols',
                // redirect: '/hols/jobCalendar',
                // redirect: '/hols/empHols',
                component: () => import('@/pages/hols/Index.vue'),
                meta: { title: '休假出勤' },
                children: [
                    {
                        name: '/hols/empHols',
                        path: '/hols/empHols',
                        component: () => import('@/pages/hols/EmpHols.vue'),
                        meta: { title: '审批列表' }
                    },
                    {
                        name: '/hols/plan',
                        path: '/hols/plan',
                        component: () => import('@/pages/hols/Plan.vue'),
                        meta: { title: '职员排班' }
                    },
                    {
                        name: '/hols/jobCalendar',
                        path: '/hols/jobCalendar',
                        component: () => import('@/pages/hols/JobCalendar.vue'),
                        meta: { title: '出勤休假' }
                    },
                    {
                        name: '/hols/empHolsBoss',
                        path: '/hols/empHolsBoss',
                        component: () => import('@/pages/hols/EmpHolsBoss.vue'),
                        meta: { title: '假期审批' }
                    },
                    {
                        name: '/hols/storageOvertime',
                        path: '/hols/storageOvertime',
                        component: () => import('@/pages/hols/StorageOvertime.vue'),
                        meta: { title: '跟单考核' }
                    },
                    {
                        name: '/hols/cusSerOvertime',
                        path: '/hols/cusSerOvertime',
                        component: () => import('@/pages/hols/CusSerOvertime.vue'),
                        meta: { title: '客服考核' }
                    },
                    {
                        name: '/hols/financeOvertime',
                        path: '/hols/financeOvertime',
                        component: () => import('@/pages/hols/FinanceOvertime.vue'),
                        meta: { title: '财务考核' }
                    },
                    {
                        name: '/hols/assessDelay',
                        path: '/hols/assessDelay',
                        component: () => import('@/pages/hols/AssessDelay.vue'),
                        meta: { title: '延迟记录' }
                    },
                    {
                        name: '/hols/salaryAdditional',
                        path: '/hols/salaryAdditional',
                        component: () => import('@/pages/hols/SalaryAdditional.vue'),
                        meta: { title: '考核信息' }
                    },
                    {
                        name: '/hols/empCheck',
                        path: '/hols/empCheck',
                        component: () => import('@/pages/hols/EmpCheck.vue'),
                        meta: { title: '打卡记录' }
                    },
                    {
                        name: '/hols/empRank',
                        path: '/hols/empRank',
                        component: () => import('@/pages/hols/EmpRank.vue'),
                        meta: { title: '班次查询' }
                    },
                ]
            },
            //人事管理路由
            {
                name: '/hr',
                path: '/hr',
                component: () => import('@/pages/hr/Index.vue'),
                meta: { title: '人事管理' },
                children: [
                    {
                        name: '/hr/employee',
                        path: '/hr/employee',
                        component: () => import('@/pages/hr/Employee.vue'),
                        meta: { title: '职员资料' }
                    },
                    {
                        name: '/hr/empHolsManage',
                        path: '/hr/empHolsManage',
                        component: () => import('@/pages/hr/EmpHolsManage.vue'),
                        meta: { title: '假期管理' }
                    },
                    {
                        name: '/hr/hols',
                        path: '/hr/hols',
                        component: () => import('@/pages/hr/Hols.vue'),
                        meta: { title: '假期设置' }
                    },
                    {
                        name: '/hr/set',
                        path: '/hr/set',
                        component: () => import('@/pages/hr/HRSet.vue'),
                        meta: { title: '部门职位设置' }
                    },
                    {
                        name: '/hr/ordersLoss',
                        path: '/hr/ordersLoss',
                        component: () => import('@/pages/hr/OrdersLoss.vue'),
                        meta: { title: '订单确责' }
                    },
                    {
                        name: '/hr/returnOrderLoss',
                        path: '/hr/returnOrderLoss',
                        component: () => import('@/pages/hr/ReturnOrderLoss.vue'),
                        meta: { title: '售后确责' }
                    },
                    {
                        name: '/hr/reshipLoss',
                        path: '/hr/reshipLoss',
                        component: () => import('@/pages/hr/ReshipLoss.vue'),
                        meta: { title: '补发确责' }
                    },
                    {
                        name: '/hr/empAccount',
                        path: '/hr/empAccount',
                        component: () => import('@/pages/hr/EmpAccount.vue'),
                        meta: { title: '职员账户' }
                    },
                    {
                        name: '/hr/empSalarySet',
                        path: '/hr/empSalarySet',
                        component: () => import('@/pages/hr/EmpSalarySet.vue'),
                        meta: { title: '月薪设置' }
                    },
                    {
                        name: '/hr/salaryAdditional',
                        path: '/hr/salaryAdditional',
                        component: () => import('@/pages/hr/SalaryAdditional.vue'),
                        meta: { title: '考核奖罚' }
                    },
                    {
                        name: '/hr/wages',
                        path: '/hr/wages',
                        component: () => import('@/pages/hr/Wages.vue'),
                        meta: { title: '职员月薪' }
                    },
                    {
                        name: '/hr/empRankManage',
                        path: '/hr/empRankManage',
                        component: () => import('@/pages/hr/EmpRankManage.vue'),
                        meta: { title: '班次管理' }
                    },
                    {
                        name: '/hr/workflow',
                        path: '/hr/workflow',
                        component: () => import('@/pages/hr/Workflow.vue'),
                        meta: { title: '审批查询' }
                    },
                ]
            },
            //财务管理路由
            {
                name: '/finance',
                path: '/finance',
                // redirect: '/finance/income',
                component: () => import('@/pages/finance/Index.vue'),
                meta: { title: '财务管理' },
                children: [
                    {
                        name: '/finance/income',
                        path: '/finance/income',
                        component: () => import('@/pages/finance/Income.vue'),
                        meta: { title: '主营业务收入' }
                    },
                    {
                        name: '/finance/dayBook',
                        path: '/finance/dayBook',
                        component: () => import('@/pages/finance/DayBook.vue'),
                        meta: { title: '营业日记账' }
                    },
                    {
                        name: '/finance/expend',
                        path: '/finance/expend',
                        component: () => import('@/pages/finance/Expend.vue'),
                        meta: { title: ' 主营业务支出' }
                    },
                    {
                        name: '/finance/orders',
                        path: '/finance/orders',
                        component: () => import('@/pages/finance/Orders.vue'),
                        meta: { title: '订单对账' }
                    },
                    {
                        name: '/finance/returnOrder',
                        path: '/finance/returnOrder',
                        component: () => import('@/pages/finance/ReturnOrder.vue'),
                        meta: { title: '售后对账' }
                    },
                    {
                        name: '/finance/reship',
                        path: '/finance/reship',
                        component: () => import('@/pages/finance/Reship.vue'),
                        meta: { title: '补发对账' }
                    },
                    {
                        name: '/finance/task',
                        path: '/finance/task',
                        component: () => import('@/pages/finance/Task.vue'),
                        meta: { title: '任务对账' }
                    },
                    {
                        name: '/finance/invoiceManage',
                        path: '/finance/invoiceManage',
                        component: () => import('@/pages/finance/InvoiceManage.vue'),
                        meta: { title: '发票管理' }
                    },
                    {
                        name: '/finance/orderCostManage',
                        path: '/finance/orderCostManage',
                        component: () => import('@/pages/finance/OrderCostManage.vue'),
                        meta: { title: '转账管理' }
                    },
                    {
                        name: '/finance/reimbManage',
                        path: '/finance/reimbManage',
                        component: () => import('@/pages/finance/ReimbManage.vue'),
                        meta: { title: '报销管理' }
                    },
                    {
                        name: '/finance/empWages',
                        path: '/finance/empWages',
                        component: () => import('@/pages/finance/EmpWages.vue'),
                        meta: { title: '职员薪资' }
                    },
                    {
                        name: '/finance/businessData',
                        path: '/finance/businessData',
                        component: () => import('@/pages/finance/BusinessData.vue'),
                        meta: { title: '经营数据' }
                    },
               
                ]
            },
            //小程序
            {
                name: '/minSys',
                path: '/minSys',
                component: () => import('@/pages/minSys/Index.vue'),
                meta: { title: '小程序后台' }
            },
            //文档中心
            {
                name: '/wordManage',
                path: '/wordManage',
                component: () => import('@/pages/word/WordManage.vue'),
                meta: { title: '文档管理' }
            },
            {
                name: '/word',
                path: '/word',
                component: () => import('@/pages/word/Word.vue'),
                meta: { title: '文档中心' }
            },
            //数据财务块
            {
                name: '/datas',
                path: '/datas',
                // redirect: '/storage/monthOrderData',
                component: () => import('@/pages/datas/Index.vue'),
                meta: { title: '数据参谋' },
                children: [
                    {
                        name: '/storage/monthOrderData',
                        path: '/storage/monthOrderData',
                        component: () => import('@/pages/storage/MonthOrderData.vue'),
                        meta: { title: '店铺月报表' }
                    },
                    {
                        name: '/storage/platformData',
                        path: '/storage/platformData',
                        component: () => import('@/pages/storage/PlatformData.vue'),
                        meta: { title: '店铺年报表' }
                    },
                    {
                        name: '/storage/returnOrderData',
                        path: '/storage/returnOrderData',
                        component: () => import('@/pages/storage/ReturnOrderData.vue'),
                        meta: { title: '售后报表' }
                    },

                    {
                        name: '/finance/profitData',
                        path: '/finance/profitData',
                        component: () => import('@/pages/finance/ProfitData.vue'),
                        meta: { title: '财务报表' }
                    },
                    {
                        name: '/finance/incomeData',
                        path: '/finance/incomeData',
                        component: () => import('@/pages/finance/IncomeData.vue'),
                        meta: { title: '营收报表' }
                    },
                    {
                        name: '/finance/expendData',
                        path: '/finance/expendData',
                        component: () => import('@/pages/finance/ExpendData.vue'),
                        meta: { title: '支出报表' }
                    },
                    {
                        name: '/storage/analysisData',
                        path: '/storage/analysisData',
                        component: () => import('@/pages/storage/AnalysisData.vue'),
                        meta: { title: '仓库报表' }
                    },
                    {
                        name: '/finance/reimb',
                        path: '/finance/reimb',
                        component: () => import('@/pages/finance/Reimb.vue'),
                        meta: { title: '报销查询' }
                    },
                    {
                        name: '/datas/orderLoss',
                        path: '/datas/orderLoss',
                        component: () => import('@/pages/datas/OrderLoss.vue'),
                        meta: { title: '订单损失' }
                    },
                ]
            },


            //任务管理路由
            {
                name: '/sx',
                path: '/sx',
                // redirect: '/task/task',
                component: () => import('@/pages/sx/Index.vue'),
                meta: { title: '思行' },
                children: [
                    {
                        name: '/sx/sxDraw',
                        path: '/sx/sxDraw',
                        component: () => import('@/pages/sx/SXDraw.vue'),
                        meta: { title: '思行绘图' }
                    },
                    {
                        name: '/sx/goodsPrice',
                        path: '/sx/goodsPrice',
                        component: () => import('@/pages/sx/GoodsPrice.vue'),
                        meta: { title: '商品报价' }
                    },
                    {
                        name: '/sx/sxGoodsManage',
                        path: '/sx/sxGoodsManage',
                        component: () => import('@/pages/sx/SxGoodsManage.vue'),
                        meta: { title: '商品管理' }
                    },
                    {
                        name: '/sx/sxEditGoods',
                        path: '/sx/sxEditGoods',
                        component: () => import('@/pages/sx/SxEditGoods.vue'),
                        meta: { title: '商品编辑' }
                    },
                ]
            },
        ]
    },

    //重定向
    {
        path: '/',
        redirect: '/login'
    }
]

export default routes

